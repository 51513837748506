import { useLocation, Route, Switch, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

// reactstrap components
import { Container, Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import { useState, useEffect, useRef } from "react";
import SnackBarAlert from "../components/Widgets/SnackBarAlert";
import Snackbar from "@mui/material/Snackbar";

// capacitor components
import { Capacitor } from "@capacitor/core";
import { Share } from "@capacitor/share";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import { FileOpener } from "@capacitor-community/file-opener";
import write_blob from "capacitor-blob-writer";

// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import AuthFooter from "../components/Footers/AuthFooter.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import LoadingSpinner from "../components/Widgets/LoadingSpinner";
import DateLabel from "components/Widgets/DateLabel";
import axios from "axios";
import routes from "../variables/routes.js";
import AdminContext from "../AdminContext";
import Reports from "../views/Reports/Reports";
import MainErrorBoundary from "../errorBoundaries/MainErrorBoundary";
import logo from "../assets/img/logo.png";
import logoSmall from "../assets/img/logo-small.png";
import QuestionModal from "../components/Widgets/QuestionModal";
import { getBaseUrl } from "../variables/Config.js";
import { getLogs } from "../errorBoundaries/consoleOverride";

const Admin = (props) => {
  const mainContent = useRef(null);
  const numRecentPages = 15;
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [idleTimer, setIdleTimer] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertColor, setAlertColor] = useState("success");
  const history = useHistory();
  const [maxAllowedEmployees, setMaxAllowedEmployees] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isTrial, setIsTrial] = useState(false);
  const [ssid, setSSID] = useState("");
  const [numberActiveEmployees, setNumberActiveEmployees] = useState(0);
  const [secondaryActiveSubscriptions, setSecondaryActiveSubscriptions] =
    useState([]);
  const [primarySubscriptionId, setPrimarySubscriptionId] = useState(null);
  const [primarySubscriptionEmail, setPrimarySubscriptionEmail] =
    useState(null);
  const [primarySubscriptionExpiry, setPrimarySubscriptionExpiry] =
    useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [user, setUser] = useState({});
  const [companies, setCompanies] = useState([]);
  const [mobileNavVisible, setMobileNavVisible] = useState(false);
  const [company, setCompany] = useState({});
  const [appConfig, setAppConfig] = useState({});
  const [authenticatorSecret, setAuthenticatorSecret] = useState("");
  const [base64EncodedOtpSecretImg, setBase64EncodedOtpSecretImg] =
    useState("");

  const [currentEmployee, setCurrentEmployeeState] = useState(false);
  const [pay, setPay] = useState(false);
  const [payRows, setPayRows] = useState([]);
  const [superFundProviders, setSuperFundProviders] = useState([]);
  const [newSuperFundDeposits, setNewSuperFundDeposits] = useState([]);
  const [payRun, setPayRun] = useState({
    end_date: false,
    pay_period: false,
  });
  const REQUEST_INTERVAL = 10000; // 10 seconds in milliseconds
  let lastRequestTime = 0;
  let requestTimer = null;
  const [showTerminatedEmpSelect, setShowTerminatedEmpSelect] = useState(false);
  const [logoSrc, setLogoSrc] = useState(logo);
  const [onlineBackupComparisonString, setOnlineBackupComparisonString] =
    useState("");
  const [superStreamMessages, setSuperStreamMessages] = useState([]);
  const [isNative, setIsNative] = useState(false);
  const [showExpiryNotice, setShowExpiryNotice] = useState(false);

  const hasShownSubscriptionExpiryNotice = () => {
    // Check local storage for the last time the subscription expiry notice was shown and if less that 24 hours ago, return true
    const lastShown = localStorage.getItem("lastShownSubscriptionExpiryNotice");
    if (lastShown) {
      const lastShownDate = new Date(lastShown);
      const now = new Date();
      const diff = now - lastShownDate;
      const diffHours = diff / (1000 * 60 * 60);
      if (diffHours < 24) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    // Check if we need to show the expiry notice.
    if (!primarySubscriptionExpiry) return;

    const expiryDate = new Date(primarySubscriptionExpiry);
    const today = new Date();
    const sixtyDaysFromToday = new Date(today);
    sixtyDaysFromToday.setDate(today.getDate() + 60);

    // Show the expiry notice only if today is before the expiry date.
    if (
      today <= expiryDate && // Ensure it hasn't expired
      expiryDate <= sixtyDaysFromToday && // Ensure it's within 60 days
      !hasShownSubscriptionExpiryNotice() // Ensure it hasn't been shown already
    ) {
      localStorage.setItem("lastShownSubscriptionExpiryNotice", new Date());
      setShowExpiryNotice(true);
    }
  }, [primarySubscriptionExpiry]);

  const closeExpiryNotice = () => {
    setShowExpiryNotice(false);
    localStorage.setItem("lastShownSubscriptionExpiryNotice", new Date());
  };

  useEffect(() => {
    const checkIsNativePlatform = async () => {
      const isNativePlatform = Capacitor.isNativePlatform();
      setIsNative(isNativePlatform);
    };

    checkIsNativePlatform();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setLogoSrc(logoSmall); // Small logo for small screens
      } else {
        setLogoSrc(logo); // Default logo
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call it once to set the initial state

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const constants = {
    BASE_URL: getBaseUrl(),
    DEFAULT_COMPUTER_NAME: "LP Web App",
    WORKING_DAYS: [
      { id: "Sunday", format_name: "Sunday" },
      { id: "Monday", format_name: "Monday" },
      { id: "Tuesday", format_name: "Tuesday" },
      { id: "Wednesday", format_name: "Wednesday" },
      { id: "Thursday", format_name: "Thursday" },
      { id: "Friday", format_name: "Friday" },
      { id: "Saturday", format_name: "Saturday" },
    ],
    Pay: {
      COMPLETE: "COMPLETED",
    },
    LEAVE_REQUEST_STATUSES: {
      PENDING: "Pending",
      APPROVED: "Approved",
      DENIED: "Denied",
      CANCELLED: "Cancelled",
    },
    TERMINATION_REASONS: {
      TERMINATION: "Ordinary Termination",
      INVALIDITY: "Employee can no longer work due to ill-health",
      DEATH_DEPENDENT: "Death of Employee, benefits paid to dependent",
      DEATH_NON_DEPENDENT: "Death of Employee, benefits paid to non-dependent",
      DEATH_ESTATE: "Death of Employee, benefits paid to estate",
      REDUNDANCY: "Genuine Redundancy or Early Retirement Scheme",
    },
    CESSATION_TYPES: {
      V: "Voluntary Cessation: Resignation or retirement initiated by the employee",
      D: "Dismissal: Employer-initiated termination due to misconduct or an employee's inability to perform work",
      C: "Contract Cessation: Natural conclusion of a limited employment",
      T: "Transfer: Administrative transfer of employment to another business, employer or payroll system",
    },
    SUPER_BASED_ON_DICT: {
      OTE: "Ordinary Time Earned",
      GROSS: "Gross Pay",
    },
    SUPER_DEPOSIT_STATUS: {
      PAID: "Paid",
      UNPAID: "Unpaid",
    },
    SUPERSTREAM_STATUSES: [
      "NEW",
      "UNSENT",
      "SENT",
      "COMPLETE",
      "MANUALLY_COMPLETE",
      "FAILED",
      "PARTIAL",
      "FAILED_SEND",
      "FAILED_VALIDATION",
      "PROGRESSIVE",
      "MANUALLY_FAILED",
      "AUTO_COMPLETE",
    ],
    STP_EMPLOYMENT_STATUS: {
      C: "Casual",
      D: "Death Beneficiary",
      F: "Full Time",
      P: "Part Time",
      Unclassified: "Unclassified",
      V: "Voluntary Agreement",
    },
    MILLISECONDS_PER_HOUR: 3600000,
    MILLISECONDS_PER_MINUTE: 60000,
    MILLISECONDS_PER_SECOND: 1000,
    STP_INCOME_STREAMS: {
      SAW: "Salary & Wages",
      CHP: "Closely Held Payee",
      WHM: "Working Holiday Maker",
      VOL: "Voluntary Agreement",
    },
    STPAllowanceCategories: {
      CD: "Cents per KM",
      AD: "Award Transport",
      LD: "Laundry",
      MD: "Overtime Meals",
      RD: "Domestic & Overseas Travel/Accommodation",
      TD: "Tools",
      KN: "Tasks",
      QN: "Qualifications/Certificates",
      OD: "Other",
      Unclassified: "Unclassified",
    },
    DAYS_OF_WEEK: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    STATES: {
      ACT: "ACT",
      NSW: "NSW",
      NT: "NT",
      QLD: "QLD",
      SA: "SA",
      TAS: "TAS",
      VIC: "VIC",
      WA: "WA",
    },
    PAY_PERIODS: {
      WEEKLY: "Weekly",
      FORTNIGHTLY: "Fortnightly",
      MONTHLY: "Monthly",
    },
    COUNTRY_CODES: {
      AD: "Andorra",
      AE: "United Arab Emirates",
      AF: "Afghanistan",
      AG: "Antigua and Barbuda",
      AI: "Anguilla",
      AL: "Albania",
      AM: "Armenia",
      AN: "Netherlands Antilles",
      AO: "Angola",
      AQ: "Antarctica ",
      AR: "Argentina",
      AS: "American Samoa",
      AT: "Austria",
      AU: "Australia",
      AW: "Aruba",
      AX: "Aland Islands",
      AZ: "Azerbaijan",
      BA: "Bosnia and Herzegovina",
      BB: "Barbados",
      BD: "Bangladesh",
      BE: "Belgium",
      BF: "Burkina Faso",
      BG: "Bulgaria",
      BH: "Bahrain",
      BI: "Burundi",
      BJ: "Benin",
      BL: "Saint-Barthélemy",
      BM: "Bermuda",
      BN: "Brunei Darussalam",
      BO: "Bolivia",
      BR: "Brazil",
      BS: "Bahamas",
      BT: "Bhutan",
      BV: "Bouvet Island",
      BW: "Botswana",
      BY: "Belarus",
      BZ: "Belize",
      CA: "Canada ",
      CC: "Cocos (Keeling) Islands",
      CD: "Congo, (Kinshasa)",
      CF: "Central African Republic",
      CG: "Congo (Brazzaville) ",
      CH: "Switzerland",
      CI: "Ivory Coast",
      CK: "Cook Islands ",
      CL: "Chile",
      CM: "Cameroon",
      CN: "China",
      CO: "Colombia",
      CR: "Costa Rica",
      CU: "Cuba",
      CV: "Cape Verde",
      CX: "Christmas Island",
      CY: "Cyprus",
      CZ: "Czech Republic",
      DE: "Germany ",
      DJ: "Djibouti",
      DK: "Denmark",
      DM: "Dominica",
      DO: "Dominican Republic",
      DZ: "Algeria",
      EC: "Ecuador",
      EE: "Estonia",
      EG: "Egypt",
      EH: "Western Sahara ",
      ER: "Eritrea",
      ES: "Spain",
      ET: "Ethiopia",
      FI: "Finland",
      FJ: "Fiji",
      FK: "Falkland Islands (Malvinas) ",
      FM: "Micronesia, Federated States of",
      FO: "Faroe Islands",
      FR: "France",
      GA: "Gabon",
      GB: "United Kingdom",
      GD: "Grenada",
      GE: "Georgia",
      GF: "French Guiana",
      GG: "Guernsey",
      GH: "Ghana",
      GI: "Gibraltar ",
      GL: "Greenland",
      GM: "Gambia",
      GN: "Guinea",
      GP: "Guadeloupe",
      GQ: "Equatorial Guinea",
      GR: "Greece",
      GS: "South Georgia and the South Sandwich Islands",
      GT: "Guatemala",
      GU: "Guam",
      GW: "Guinea-Bissau",
      GY: "Guyana",
      HK: "Hong Kong, SAR China",
      HM: "Heard and Mcdonald Islands",
      HN: "Honduras",
      HR: "Croatia",
      HT: "Haiti",
      HU: "Hungary",
      ID: "Indonesia",
      IE: "Ireland",
      IL: "Israel ",
      IM: "Isle of Man ",
      IN: "India",
      IO: "British Indian Ocean Territory",
      IQ: "Iraq",
      IR: "Iran, Islamic Republic of",
      IS: "Iceland",
      IT: "Italy ",
      JE: "Jersey",
      JM: "Jamaica",
      JO: "Jordan",
      JP: "Japan",
      KE: "Kenya",
      KG: "Kyrgyzstan",
      KH: "Cambodia",
      KI: "Kiribati",
      KM: "Comoros",
      KN: "Saint Kitts and Nevis",
      KP: "Korea (North)",
      KR: "Korea (South)",
      KW: "Kuwait",
      KY: "Cayman Islands ",
      KZ: "Kazakhstan",
      LA: "Lao PDR",
      LB: "Lebanon",
      LC: "Saint Lucia",
      LI: "Liechtenstein",
      LK: "Sri Lanka",
      LR: "Liberia",
      LS: "Lesotho",
      LT: "Lithuania",
      LU: "Luxembourg",
      LV: "Latvia",
      LY: "Libya",
      MA: "Morocco",
      MC: "Monaco",
      MD: "Moldova",
      ME: "Montenegro",
      MF: "Saint-Martin (French part)",
      MG: "Madagascar",
      MH: "Marshall Islands",
      MK: "Macedonia, Republic of",
      ML: "Mali",
      MM: "Myanmar",
      MN: "Mongolia",
      MO: "Macao, SAR China",
      MP: "Northern Mariana Islands",
      MQ: "Martinique",
      MR: "Mauritania",
      MS: "Montserrat",
      MT: "Malta",
      MU: "Mauritius",
      MV: "Maldives",
      MW: "Malawi",
      MX: "Mexico",
      MY: "Malaysia",
      MZ: "Mozambique",
      NA: "Namibia",
      NC: "New Caledonia",
      NE: "Niger",
      NF: "Norfolk Island",
      NG: "Nigeria",
      NI: "Nicaragua",
      NL: "Netherlands",
      NO: "Norway",
      NP: "Nepal",
      NR: "Nauru",
      NU: "Niue ",
      NZ: "New Zealand",
      OM: "Oman",
      PA: "Panama",
      PE: "Peru",
      PF: "French Polynesia",
      PG: "Papua New Guinea",
      PH: "Philippines",
      PK: "Pakistan",
      PL: "Poland",
      PM: "Saint Pierre and Miquelon ",
      PN: "Pitcairn ",
      PR: "Puerto Rico",
      PS: "Palestinian Territory",
      PT: "Portugal",
      PW: "Palau",
      PY: "Paraguay",
      QA: "Qatar",
      RE: "Réunion",
      RO: "Romania",
      RS: "Serbia",
      RU: "Russian Federation ",
      RW: "Rwanda",
      SA: "Saudi Arabia",
      SB: "Solomon Islands",
      SC: "Seychelles",
      SD: "Sudan",
      SE: "Sweden",
      SG: "Singapore",
      SH: "Saint Helena ",
      SI: "Slovenia",
      SJ: "Svalbard and Jan Mayen Islands ",
      SK: "Slovakia",
      SL: "Sierra Leone",
      SM: "San Marino",
      SN: "Senegal",
      SO: "Somalia",
      SR: "Suriname",
      SS: "South Sudan",
      ST: "Sao Tome and Principe",
      SV: "El Salvador",
      SY: "Syrian Arab Republic (Syria)",
      SZ: "Swaziland",
      TC: "Turks and Caicos Islands ",
      TD: "Chad",
      TF: "French Southern Territories ",
      TG: "Togo",
      TH: "Thailand",
      TJ: "Tajikistan",
      TK: "Tokelau ",
      TL: "Timor-Leste",
      TM: "Turkmenistan",
      TN: "Tunisia",
      TO: "Tonga",
      TR: "Turkey",
      TT: "Trinidad and Tobago",
      TV: "Tuvalu",
      TW: "Taiwan, Republic of China ",
      TZ: "Tanzania, United Republic of ",
      UA: "Ukraine",
      UG: "Uganda",
      UM: "US Minor Outlying Islands ",
      US: "United States of America",
      UY: "Uruguay",
      UZ: "Uzbekistan",
      VA: "Holy See (Vatican City State)",
      VC: "Saint Vincent and Grenadines",
      VE: "Venezuela (Bolivarian Republic)",
      VG: "British Virgin Islands",
      VI: "Virgin Islands, US",
      VN: "Viet Nam",
      VU: "Vanuatu",
      WF: "Wallis and Futuna Islands ",
      WS: "Samoa",
      YE: "Yemen",
      YT: "Mayotte",
      ZA: "South Africa",
      ZM: "Zambia",
      ZW: "Zimbabwe",
    },
    DEDUCTION_TYPES: {
      NORMAL: "Normal",
      UNION_FEES: "Union/Association Fees",
      CHARITY: "Charity/Workplace Giving",
      CHILD_SUPPORT_GARNISHEE: "Child Support Garnishee",
      CHILD_SUPPORT_DEDUCTION: "Child Support Deduction",
    },
  };

  const isBase64 = (str) => {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  };

  const getBase64Data = (data) => {
    const base64Prefix = "data:";
    if (isBase64(data)) {
      return data;
    }
    if (data.startsWith(base64Prefix)) {
      return data.split(",")[1];
    }
    // Assume the data is not base64 encoded and convert it to base64
    return btoa(data);
  };

  // Logout function
  const logout = () => {
    // Wipe out session data
    setCompanies([]);
    setAppConfig({});
    setCurrentEmployeeState(false);
    setMaxAllowedEmployees(0);
    setIsAdmin(false);
    setIsTrial(false);
    setSSID("");
    setNumberActiveEmployees(0);
    setUser({});
    setPrimarySubscriptionId(null);
    setPrimarySubscriptionEmail(null);
    setPrimarySubscriptionExpiry(null);
    setSecondaryActiveSubscriptions([]);
    setCustomerId(null);
    setPay({});
    setSuperFundProviders([]);
    setPayRows([]);
    setNewSuperFundDeposits([]);
    setPayRun({
      end_date: false,
      pay_period: false,
    });
    setSuperStreamMessages([]);

    // Clear the token in storage
    localStorage.setItem("token", "");

    // Redirect to login
    history.push("/auth/login");
  };

  // // Register event handlers for user activity
  // useEffect(() => {
  //   const events = [
  //     "load",
  //     "mousemove",
  //     "mousedown",
  //     "click",
  //     "scroll",
  //     "keypress",
  //   ];

  //   const resetTimer = () => {
  //     clearTimeout(idleTimer);
  //     setIdleTimer(setTimeout(logout, 30 * 60 * 1000)); // Logout after 30 mins of inactivity
  //   };

  //   for (let i in events) {
  //     window.addEventListener(events[i], resetTimer, false);
  //   }

  //   return () => {
  //     // Cleanup
  //     for (let i in events) {
  //       window.removeEventListener(events[i], resetTimer, false);
  //     }
  //   };
  // }, [idleTimer]);
  const completedPayRows = () => {
    return (
      payRows.filter((payRow) => payRow.status === constants.Pay.COMPLETE) || []
    );
  };

  const loginToWebAccount = (purchasePage = false) => {
    getRequest(constants.BASE_URL + `/tools/instant-login-link`, (response) => {
      if (response.data.login_link) {
        let loginLink = response.data.login_link;

        // Append 'purchase=true' if purchasePage is true
        if (purchasePage) {
          const url = new URL(loginLink);
          url.searchParams.append("purchase", "true");
          loginLink = url.toString();
        }

        window.open(loginLink, "_blank");
        // Go back to the previous page
        window.history.back();
      }
    });
  };

  const loadDefaultCompanyAttempt = () => {
    const now = Date.now();

    // If a request was made recently, wait until REQUEST_INTERVAL has passed
    if (now - lastRequestTime < REQUEST_INTERVAL) {
      clearTimeout(requestTimer); // Clear the previous timer
      requestTimer = setTimeout(loadDefaultCompanyAttempt, REQUEST_INTERVAL);
      return;
    }

    lastRequestTime = now; // Update the last request time

    // API request function
    getRequest(constants.BASE_URL + `/company/`, (response) => {
      // Check if they've expired
      if (response.data.customer_expired) {
        history.push("/admin/settings/subscription-expired");
        return;
      }

      // Handle the response as before
      if (response.status === 401) {
        console.log("Redirecting to login because of error 401.");
        logout();
      } else if (
        !response.data.companies ||
        response.data.companies.length === 0
      ) {
        history.push("/admin/no_companies_exist");
      } else if (response.data.companies.length === 1 && !user) {
        setCompany(response.data.companies[0]);
        setPayRun({
          end_date: false,
          pay_period: false,
        });
        if (editAccess("pays")) {
          history.push("/admin/pays");
        } else {
          history.push("/admin/entities/select");
        }
      } else if (response.data.companies.length > 1 && !user) {
        const defaultCompanyName =
          response.data.app_config.default_company.value;
        if (defaultCompanyName) {
          const defaultCompany = response.data.companies.find(
            (company) => company.name === defaultCompanyName
          );
          if (defaultCompany) {
            setCompany(defaultCompany);
            setPayRun({
              end_date: false,
              pay_period: false,
            });
            if (editAccess("pays")) {
              history.push("/admin/pays");
            } else {
              history.push("/admin/entities/select");
            }
          }
        } else {
          history.push("/admin/entities/select");
        }
      }
    });
  };

  useEffect(() => {
    if (isLoading === false) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      mainContent.current.scrollTop = 0;
    }
  }, [location]);

  useEffect(() => {
    const initRecentPages = (location) => {
      const currentPath = location.pathname;
      // Save recent pages
      // Add the currentPath to localStorage recentPages and rotate the array so no more than 10 items are stored
      let recentPages = JSON.parse(localStorage.getItem("recentPages")) || [];

      // Simply reorder the array if the currentPath is already in the array
      if (recentPages.includes(currentPath)) {
        recentPages = recentPages.filter((page) => page !== currentPath);
      }

      recentPages.unshift(currentPath);
      recentPages = recentPages.slice(0, numRecentPages);
      localStorage.setItem("recentPages", JSON.stringify(recentPages));
    };

    // Call the logic once when the component mounts
    initRecentPages(history.location);

    const unlisten = history.listen(initRecentPages);

    // Perform the cleanup
    return () => {
      unlisten();
    };
  }, [company, history]);

  // Add a useeffect hook to set the current employee if the company changes
  useEffect(() => {
    if (company && company.employees && company.employees.length > 0) {
      // Get the first
      setCurrentEmployee(
        // Find the first employee that is not terminated
        company.employees.find((employee) => employee.is_terminated === false)
      );
    }
  }, [company.name]);

  useEffect(() => {
    const catchBadData = () => {
      // Check if localStorage.token is valid
      if (!localStorage.getItem("token")) {
        console.log("Redirecting to login because of no token.");
        logout();
      }

      // If no company is selected or company.employees is undefined, redirect to the entity select screen
      if (!company || company.employees === undefined) {
        console.log("Pushing from no company redirects");
        loadDefaultCompanyAttempt();
        return;
      }

      if (company && company.employees && company.employees.length > 0) {
        // Select an employee if no one is selected
        if (currentEmployee === undefined || currentEmployee === null) {
          setCurrentEmployee(company.employees[0]);
        }
      }
    };

    catchBadData();
  }, []);

  const setCurrentEmployee = (employee) => {
    setCurrentEmployeeState(employee);
    // We also need to update the employee within the company.employees array
    // This is so that the employee list widget will update
    let newCompany = { ...company };
    if (newCompany.employees !== undefined) {
      newCompany.employees = newCompany.employees.map((emp) => {
        if (employee && emp.id === employee.id) {
          return employee;
        } else {
          return emp;
        }
      });
    }

    if (!newCompany || newCompany === undefined) {
      return;
    }

    setCompany(newCompany);

    // We also need to update the employee within the companies array
    // This is so that the employee list widget will update
    let newCompanies = companies.map((comp) => {
      if (comp.id === company.id) {
        return newCompany;
      } else {
        return comp;
      }
    });
    setCompanies(newCompanies);
  };

  const nonDeletedEmployees = () => {
    if (!company || !company.employees) {
      return [];
    }
    return company.employees.filter((emp) => emp.is_deleted === false);
  };

  const refreshSession = (response) => {
    if (response.data.access_token) {
      // Update the token cookie with the new token
      // Calculate the number of days that corresponds to 30 minutes

      const minutes = 30;
      const oneDay = 24 * 60;
      const expires = minutes / oneDay;

      const tokenData = JSON.stringify({
        token: response.data.access_token,
        expires: expires,
      });

      localStorage.setItem("token", tokenData);
    }

    if (response.data.app_config) {
      setAppConfig(response.data.app_config);
    }

    if (response.data.authenticator_secret) {
      setAuthenticatorSecret(response.data.authenticator_secret);
    }

    if (response.data.base_64_encoded_otp_secret_img) {
      setBase64EncodedOtpSecretImg(
        response.data.base_64_encoded_otp_secret_img
      );
    }

    if (response.data.employee) {
      setCurrentEmployee(response.data.employee);
    }

    if (response.data.max_allowed_employees) {
      setMaxAllowedEmployees(response.data.max_allowed_employees);
    }

    if (response.data.is_admin) {
      setIsAdmin(response.data.is_admin);
    }

    if (response.data.is_trial) {
      setIsTrial(response.data.is_trial);
    }

    if (response.data.ssid) {
      setSSID(response.data.ssid);
    }

    if (response.data.total_active_employees) {
      setNumberActiveEmployees(response.data.total_active_employees);
    }

    if (response.data.user) {
      setUser(response.data.user);
    }

    if (response.data.primary_subscription_id) {
      setPrimarySubscriptionId(response.data.primary_subscription_id);
    }

    if (response.data.primary_subscription_email) {
      setPrimarySubscriptionEmail(response.data.primary_subscription_email);
    }

    if (response.data.primary_subscription_expiry) {
      setPrimarySubscriptionExpiry(response.data.primary_subscription_expiry);
    }

    if (response.data.secondary_active_subscriptions) {
      setSecondaryActiveSubscriptions(
        response.data.secondary_active_subscriptions
      );
    }

    if (response.data.customer_id) {
      setCustomerId(response.data.customer_id);
    }

    if (response.data.customer_expired) {
      history.push("/admin/settings/subscription-expired");
      return;
    }

    if (response.data.pay) {
      setPay(response.data.pay);
    }

    if (response.data.super_fund_providers !== undefined) {
      setSuperFundProviders(response.data.super_fund_providers);
    }

    if (response.data.companies) {
      // Set company and companies to the response data
      let companies = response.data.companies;
      let updatedCompany = companies.find((comp) => comp.name === company.name);

      // Check if the current company exists within the companies array
      if (updatedCompany === undefined && companies.length > 0) {
        // The company does not exist in the companies array
        setCompany(companies[0]);
        updatedCompany = companies[0];
      }

      if (companies !== undefined && companies.length > 0) {
        if (updatedCompany === undefined && !company.id) {
          updatedCompany = companies[0];
        }

        setCompanies(companies);
        if (updatedCompany !== undefined) {
          setCompany(updatedCompany);
        }
      }
    }
  };

  // Retrieve the token from localStorage
  const tokenString = localStorage.getItem("token");

  // Initialize token variable
  let token = null;

  // Check if tokenString is not null before parsing
  if (tokenString) {
    try {
      const parsedToken = JSON.parse(tokenString);
      if (parsedToken && parsedToken.token) {
        token = parsedToken.token;
      }
    } catch (error) {
      console.error("Error parsing token from localStorage", error);
    }
  }

  // Create axios instance with common settings
  const api = axios.create({
    timeout: 120000,
    headers: token ? { Authorization: `Bearer ${token}` } : {},
  });

  const isValidUrl = (url) => {
    const urlObj = new URL(url);
    for (const [, value] of urlObj.searchParams) {
      if (typeof value === "undefined") {
        return false;
      }
    }
    if (url.includes("undefined")) {
      return false;
    }
    return true;
  };

  // Handle all responses in a unified way
  const handleResponse = (response, callback) => {
    refreshSession(response);

    if (typeof callback === "function") {
      callback(response);
    }
  };

  // Handle errors in a unified way
  const handleError = (error) => {
    if (axios.isCancel(error)) {
      console.log("Request cancelled");
      return;
    }

    if (error.response) {
      if (error.response.status === 401 || error.response.status === 400) {
        console.log("Redirecting to login because of error 400 or 401.");
        logout();
      } else {
        console.error("Request failed:", error);
        throw error;
      }
    }

    // Check for 408 timeout error
    else if (error.code === "ECONNABORTED") {
      // Timeout error
      console.error("There was a timeout error:", error);
    } else if (error.code === "ERR_NETWORK") {
      // Handle network errors
      console.error("There was a network error:", error);
      logout();
    } else {
      // Handle network errors
      console.error("Redirecting to login because of an unknown error:", error);
      logout();
    }
  };

  const fontSizes = () => {
    let sizes = {};
    for (let i = 5; i <= 34; i++) {
      sizes[i] = i;
    }
    return sizes;
  };

  const checkUserAccessAndRedirect = (url) => {
    if (!user || !user.global_permissions || !user.user_companies) {
      return;
    }

    const urlObject = new URL(url);
    const pathname = urlObject.pathname;
    const zone = pathname.split("/")[1].toUpperCase().replace("-", "_");

    // Check the global permissions if the user is in a global zone
    let globalPermissions = user.global_permissions.filter(
      (permission) => zone === permission.zone && permission.is_locked
    );

    if (globalPermissions.length > 0) {
      history.push("/admin/user-restricted");
      return;
    }

    // Check company-specific permissions if not in a global zone
    user.user_companies.forEach((user_company) => {
      user_company.user_permissions.forEach((permission) => {
        if (company.id === user_company.company_id) {
          if (zone === permission.zone && permission.is_locked) {
            history.push("/admin/user-restricted");
          }
        }
      });
    });
  };

  const editAccess = (zone) => {
    // This function advises whether a view should be editable or read only.
    // It only controls the UI. The backend runs similar checks to cover proper security.
    if (!user || !user.global_permissions || !user.user_companies) {
      return true;
    }

    const zoneUpper = zone.toUpperCase().replace("-", "_");

    // Check the global permissions if the user is in a global zone
    let globalPermissions = user.global_permissions.filter(
      (permission) => zoneUpper === permission.zone && permission.is_editable
    );

    if (globalPermissions.length > 0) {
      return true;
    }

    // Check company-specific permissions if not in a global zone
    let companyPermissions = user.user_companies.filter(
      (user_company) => company.id === user_company.company_id
    );

    if (companyPermissions.length > 0) {
      let companyPermission = companyPermissions[0].user_permissions.filter(
        (permission) => zoneUpper === permission.zone && permission.is_editable
      );

      if (companyPermission.length > 0) {
        return true;
      }
    }

    return false;
  };

  const checkResponseForPermissionErrors = (response) => {
    // Redirect to login/forbidden screen if required
    if (response.status === 401) {
      logout();
    }
    if (user && response.status === 403) {
      console.log("Redirecting to user forbidden screen because of error 403.");
      history.push("/admin/user-restricted");
    }
  };

  const makeRequest = async (
    method,
    url,
    payload = null,
    responseCallback = null,
    cancelTokenSource = null
  ) => {
    if (!isValidUrl(url)) {
      console.log("Redirecting to login because of invalid URL." + url);
      loadDefaultCompanyAttempt();
      return;
    }

    // Check if the token exists before talking to backend
    if (!localStorage.getItem("token")) {
      console.log("Redirecting to login because of no token.");
      logout();
      return;
    }

    // Finally before continuing, do som basic validation on user access to save time and resources. The main check is on the server side of course.

    checkUserAccessAndRedirect(url);

    try {
      const config = {
        cancelToken: cancelTokenSource ? cancelTokenSource.token : undefined,
      };

      let response;
      switch (method) {
        case "get":
          response = await api.get(url, config);
          if (response.headers.get("comparison-string")) {
            setOnlineBackupComparisonString(
              response.headers.get("comparison-string")
            );
          }
          break;
        case "post":
          response = await api.post(url, payload, config);
          break;
        case "put":
          response = await api.put(url, payload, config);
          break;
        case "delete":
          response = await api.delete(url, config);
          break;
        default:
          throw new Error(`Invalid method: ${method}`);
      }

      checkResponseForPermissionErrors(response);

      handleResponse(response, responseCallback);
      return response;
    } catch (error) {
      if (error.response && error.response.status) {
        console.log(error);
        checkResponseForPermissionErrors(error.response);
      } else {
        handleError(error);
      }
    }
  };

  const postRequest = (
    url,
    payload,
    responseCallback,
    cancelTokenSource = null
  ) => makeRequest("post", url, payload, responseCallback, cancelTokenSource);

  const getRequest = (url, responseCallback = null, cancelTokenSource = null) =>
    makeRequest("get", url, null, responseCallback, cancelTokenSource);

  const putRequest = (
    url,
    payload,
    responseCallback,
    cancelTokenSource = null
  ) => makeRequest("put", url, payload, responseCallback, cancelTokenSource);

  const deleteRequest = (url, responseCallback, cancelTokenSource = null) =>
    makeRequest("delete", url, null, responseCallback, cancelTokenSource);

  // This version does not touch the backend
  const initEmployees = () => {
    if (currentEmployee && company.employees !== undefined) {
      // Change the currentEmployee if the currentEmployee is terminated
      if (currentEmployee.is_terminated === true && !showTerminatedEmpSelect) {
        setCurrentEmployee(
          // Find the first employee that is not terminated
          company.employees.find((employee) => employee.is_terminated === false)
        );
      } else {
        setCurrentEmployee(
          // Find the first employee that is not terminated
          company.employees.find(
            (employee) => employee.id === currentEmployee.id
          )
        );
      }
    } else if (
      company &&
      company.employees !== undefined &&
      company.employees.length > 0
    ) {
      setCurrentEmployee(
        // Find the first employee that is not terminated
        company.employees.find((employee) => employee.is_terminated === false)
      );
    }
  };

  const sendLogsToSupport = async () => {
    const logs = getLogs();
    // Make a post request to the api to send the logs
    postRequest(
      `${constants.BASE_URL}/mobile-console-logs`,
      { logs: logs },
      (response) => {
        console.log(response);
        alert("Console logs sent");
      }
    );
  };

  const getKeyByValue = (object, value, caseInsensitive = false) => {
    let key = Object.keys(object).find((key) => {
      if (caseInsensitive) {
        return object[key].toLowerCase() === value.toLowerCase();
      } else {
        return object[key] === value;
      }
    });

    if (key === undefined) {
      return value;
    }

    return key;
  };

  function toTitleCase(str) {
    if (!str) {
      return "";
    }

    str = str.replaceAll("_", " ").toLowerCase().split(" ");
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(" ");
  }

  function snakeToTitleCase(str) {
    if (!str) {
      return "";
    }

    str = str.replaceAll("_", " ").toLowerCase().split(" ");
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }

    return str.join(" ");
  }

  const checkIsNativePlatform = async () => Capacitor.isNativePlatform();

  const saveNativeBlobFile = async (blob, fileName) => {
    return write_blob({
      path: fileName,
      directory: Directory.Cache,
      blob: blob,
    });
  };

  const handleFileDownload = async (
    fileName,
    fileData,
    fileType = "text/plain"
  ) => {
    const isNative = await checkIsNativePlatform();
    let base64Data = fileData;
    let encoding = Encoding.UTF8;

    // Decode the base64 data to UTF-8 if it's a CSV file
    if (fileType === "text/csv") {
      base64Data = atob(getBase64Data(base64Data));
      encoding = Encoding.UTF8;
    } else {
      base64Data = getBase64Data(fileData);
      encoding = Encoding.BASE64;
    }

    if (isNative) {
      const originalFilename = fileName;
      // Considering Directory.Documents and the filename, check if it already exists
      let fileExists = false;

      try {
        fileExists = await Filesystem.stat({
          path: fileName,
          directory: Directory.Documents,
        });
      } catch (e) {
        console.error("Unable to check if file exists", e);
      }

      // If file exists, append a timestamp to the filename, before the extension
      if (fileExists) {
        const fileParts = fileName.split(".");

        const timestamp = new Date().toISOString().replace(/[^0-9]/g, "");
        fileName = `${fileParts[0]}_${timestamp}.${fileParts[1]}`;
      }

      try {
        const writtenFile = await Filesystem.writeFile({
          path: fileName,
          data: base64Data,
          directory: Directory.Documents,
          encoding: encoding,
        });

        let fileUri = writtenFile.uri;

        // Specific handling based on file type
        if (fileType === "application/pdf") {
          await FileOpener.open({
            filePath: fileUri,
            contentType: fileType,
          });
        }

        // Ask the user if they wish to share the file and if so, execute sharing
        if (
          Capacitor.getPlatform() === "ios" ||
          window.confirm(
            "Success. Would you also like to share or send this file?"
          )
        ) {
          Share.share({
            title: fileName,
            url: fileUri,
            dialogTitle: fileName,
          });
        }
      } catch (e) {
        console.error("Unable to write or open file", e);
        alert(`Failed to download ${fileName}`);
      }
    } else {
      // Web platforms
      const downloadLink = document.createElement("a");
      downloadLink.href =
        encoding === Encoding.BASE64
          ? `data:${fileType};base64,${base64Data}`
          : `data:${fileType},${encodeURIComponent(fileData)}`;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  };

  function stripHtmlTags(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }

  const handlePDFDownload = async (fileName, pdfData) => {
    handleFileDownload(fileName, pdfData, "application/pdf");
  };

  const adminContextValue = {
    pay,
    setPay,
    currentEmployee,
    setCurrentEmployee,
    appConfig,
    setAppConfig,
    superStreamMessages,
    setSuperStreamMessages,
    mobileNavVisible,
    setMobileNavVisible,
    company,
    setCompany,
    getRequest,
    postRequest,
    putRequest,
    deleteRequest,
    editAccess,
    initEmployees,
    getKeyByValue,
    sendLogsToSupport,
    companies,
    setCompanies,
    location,
    constants,
    toTitleCase,
    snakeToTitleCase,
    history,
    payRows,
    setPayRows,
    payRun,
    setPayRun,
    superFundProviders,
    setSuperFundProviders,
    maxAllowedEmployees,
    authenticatorSecret,
    base64EncodedOtpSecretImg,
    isAdmin,
    isTrial,
    ssid,
    numberActiveEmployees,
    user,
    primarySubscriptionId,
    primarySubscriptionEmail,
    primarySubscriptionExpiry,
    secondaryActiveSubscriptions,
    customerId,
    showTerminatedEmpSelect,
    setShowTerminatedEmpSelect,
    newSuperFundDeposits,
    setNewSuperFundDeposits,
    nonDeletedEmployees,
    completedPayRows,
    setAlertColor,
    setAlertMessage,
    fontSizes,
    handlePDFDownload,
    handleFileDownload,
    checkIsNativePlatform,
    isNative,
    isLoading,
    setIsLoading,
    stripHtmlTags,
    loginToWebAccount,
  };
  const getRoutes = (routes) => {
    let hardRoutes = [
      <Route exact path={"/admin/reports"} key={"reports"}>
        <MainErrorBoundary>
          <Reports name={"Reports"} />
          <Helmet>
            <title>{"Lightning Payroll Reports"}</title>
            <meta
              name="description"
              content="Print and export a wide range of reports."
            />
          </Helmet>
        </MainErrorBoundary>
      </Route>,
    ];

    return hardRoutes.concat(
      routes.map((prop, key) => {
        if (prop.layout === "/admin") {
          if (prop.options && prop.options.length > 0) {
            return prop.options.map((innerProp, key) => {
              return (
                <Route exact path={prop.layout + innerProp.path} key={key}>
                  <MainErrorBoundary>
                    <innerProp.component
                      subComponent={innerProp.subComponent}
                      name={innerProp.name}
                    />
                    <Snackbar
                      open={!!alertMessage}
                      autoHideDuration={6000}
                      onClose={() => {
                        setAlertMessage(null);
                        setAlertColor(alertColor);
                      }}
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                      <SnackBarAlert
                        onClose={() => {
                          setAlertMessage("");
                          setAlertColor(alertColor);
                        }}
                        severity={alertColor}
                      >
                        {alertMessage}
                      </SnackBarAlert>
                    </Snackbar>
                    <Helmet>
                      <title>{innerProp.name}</title>
                      {innerProp.shortDescription ? (
                        <meta
                          name="description"
                          content={innerProp.shortDescription}
                        />
                      ) : null}
                    </Helmet>
                  </MainErrorBoundary>
                </Route>
              );
            });
          } else {
            return (
              <Route exact path={prop.layout + prop.path} key={key}>
                <MainErrorBoundary>
                  <prop.component
                    subComponent={prop.subComponent}
                    name={prop.name}
                  />
                  <Snackbar
                    open={!!alertMessage}
                    autoHideDuration={6000}
                    onClose={() => {
                      setAlertMessage(null);
                      setAlertColor(alertColor);
                    }}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  >
                    <SnackBarAlert
                      onClose={() => {
                        setAlertMessage("");
                        setAlertColor(alertColor);
                      }}
                      severity={alertColor}
                    >
                      {alertMessage}
                    </SnackBarAlert>
                  </Snackbar>
                  <Helmet>
                    <title>{prop.name}</title>
                    {prop.shortDescription ? (
                      <meta
                        name="description"
                        content={prop.shortDescription}
                      />
                    ) : null}
                  </Helmet>
                </MainErrorBoundary>
              </Route>
            );
          }
        } else {
          return null;
        }
      })
    );
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <AdminContext.Provider value={adminContextValue}>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/entities/select",
          imgSrc: logoSrc,
          imgAlt: "...",
        }}
        logoOriginal={{
          innerLink: "/admin/entities/select",
          imgSrc: logo,
          imgAlt: "...",
        }}
      />
      {isLoading ? (
        <div className="main-content" ref={mainContent}>
          <Container fluid>
            <LoadingSpinner />
          </Container>
        </div>
      ) : (
        <div className="main-content" ref={mainContent}>
          <AdminNavbar
            {...props}
            brandText={getBrandText(props.location.pathname)}
          />
          <Switch>
            {getRoutes(routes)}
            {/* <Redirect from="*" to="/admin/entities/select" /> */}
          </Switch>
          <QuestionModal
            isOpen={onlineBackupComparisonString !== ""}
            title=""
            innerHTML={onlineBackupComparisonString}
            content=""
            onConfirm={() => {
              setOnlineBackupComparisonString("");
              getRequest(
                constants.BASE_URL + `/tools/backup/restore_auto_online`,
                (response) => {
                  loadDefaultCompanyAttempt();
                }
              );
            }}
            onDeny={() => {
              setOnlineBackupComparisonString("");
            }}
          />
          <Modal
            className="px-2"
            isOpen={showExpiryNotice}
            toggle={closeExpiryNotice}
            centered={true}
          >
            <ModalHeader
              className="w-100"
              toggle={closeExpiryNotice}
            ></ModalHeader>
            <h1 className="text-center days-one mb-0 pb-0  mx-2">
              Your {isTrial ? "Trial" : "Subscription"} Expires Soon
            </h1>
            <ModalBody className="mx-2">
              <div className="w-100 ">
                <h3 className="text-center mb-2 pt-0">
                  Expiry Date: <DateLabel value={primarySubscriptionExpiry} />
                </h3>
                <p>
                  Please click the button below to login and renew your annual
                  subscription at our homepage.
                </p>
                <p>
                  For help with this you may contact our friendly support team
                  on (07) 3051 5895 or via{" "}
                  <a href="mailto:support@lightningpayroll.com.au">
                    support@lightningpayroll.com.au
                  </a>
                </p>
                <div className="text-center">
                  <Button
                    color="warning"
                    onClick={() => loginToWebAccount(true)}
                    className="null-border-radius my-2 width-40-on-lg"
                  >
                    {isTrial ? "Purchase" : "Renew"} Now
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
          <Container fluid>
            <AuthFooter />
          </Container>
        </div>
      )}
    </AdminContext.Provider>
  );
};

export default Admin;
