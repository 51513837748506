import { useState, useContext, useEffect } from "react";
import AdminContext from "../../AdminContext";
import { Card, CardBody, Container, Table } from "reactstrap";
import TableWidget from "../../components/Widgets/TableWidget";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";

const TrialsDashboard = () => {
  const adminContext = useContext(AdminContext);
  const [trials, setTrials] = useState("");
  const [loading, setLoading] = useState(false);

  // Function to fetch log data
  const fetchTrials = () => {
    setLoading(true);
    adminContext.getRequest(
      adminContext.constants.BASE_URL + "/admin/recent-trials",
      (response) => {
        if (response.data.trials) {
          setTrials(response.data.trials);
          console.log(response.data.trials);
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchTrials();
  }, []);

  return (
    <Container fluid>
      <Card>
        <CardBody>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <h1 className="days-one">Trials Dashboard</h1>
              <TableWidget
                rows={trials || []}
                columns={[
                  { accessor: "subscription_id" },
                  { accessor: "expiry", widget: "DateLabel" },
                  { accessor: "date_added", widget: "DateLabel" },
                  { accessor: "subscription_trial_id" },
                  { accessor: "days_until_expiry" },
                  { accessor: "weeks_until_expiry" },
                  { accessor: "email_to_send" },
                  { accessor: "emails_sent" },
                  { accessor: "customer_email" },
                  { accessor: "referral_code" },
                  { accessor: "google_ads_click_id" },
                  { accessor: "google_ads_keywords" },
                  { accessor: "how_did_you_hear" },
                ]}
              />
            </>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default TrialsDashboard;
