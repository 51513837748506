/*eslint-disable*/
import { useState, useCallback, useEffect, useContext } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import React from "react";
import AdminContext from "../../AdminContext";

// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Modal,
  Button,
} from "reactstrap";
import { FaWindowClose, FaCaretDown, FaCaretRight } from "react-icons/fa";
import Tooltip from "../Widgets/Tooltip";

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();
  const [showRecentPages, setShowRecentPages] = useState(false);
  const adminContext = useContext(AdminContext);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // Used to prevent styles clashing between the accordion menu and small screen menu
  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth < 768); // 768px is the md breakpoint in Bootstrap
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  // creates the links that appear in the left menu / Sidebar
  const getRecentPages = () => {
    return JSON.parse(localStorage.getItem("recentPages")) || [];
  };

  const getNameFromPath = (path) => {
    if (path === undefined || path === "") {
      return "";
    }
    let name = "";
    for (let i = 0; i < routes.length; i++) {
      let prop = routes[i];
      if (prop.layout === "/admin") {
        let cleanPath = prop.path.replace("/admin", "");
        if (path.endsWith(cleanPath)) {
          return prop.name;
        }
        if (prop.options.length > 0) {
          for (let j = 0; j < prop.options.length; j++) {
            let innerProp = prop.options[j];
            let innerCleanPath = innerProp.path.replace("/admin", "");
            if (path.endsWith(innerCleanPath)) {
              return prop.name + ": " + (innerProp.label || innerProp.name);
            }
          }
        }
      }
    }
    return name;
  };

  const AccordionLinkItem = ({ to, icon, name, onClick, className }) => (
    <NavLink
      to={to}
      tag={NavLinkRRD}
      onClick={onClick}
      className={className}
      style={{
        display: "flex",
        alignItems: "center",
        padding: "10px 15px",
        textDecoration: "none",
      }}
    >
      <i className={icon} style={{ marginRight: "10px" }} />
      {name}
    </NavLink>
  );

  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return React.Children.toArray(
      routes.map((prop, key) => {
        // Check that the prop does not have the hide key present
        if (prop.layout !== "/auth" && !prop.hide) {
          // If prop.options exists, then it is a dropdown menu item
          // otherwise it is a regular nav item
          if (!adminContext.company.id && !prop.showWithoutCompany) {
            return null;
          }

          const toggleCollapse = () =>
            setCollapseOpen((prevState) => (prevState === key ? null : key));

          // If the user is on a native device and the screen is not small, then render the accordion menu
          if (adminContext.isNative && !isSmallScreen) {
            if (prop.options.length > 0 && !prop.path.includes("/reports")) {
              return (
                <NavItem key={key}>
                  <div>
                    <div
                      onClick={toggleCollapse}
                      className="days-one text-white accordion-menu-toggle"
                    >
                      <i
                        className={prop.icon}
                        style={{ marginRight: "29px", fontSize: "16px" }}
                      />
                      {prop.name}
                      <i className="accordion-menu-carat">
                        {collapseOpen === key ? (
                          <FaCaretDown />
                        ) : (
                          <FaCaretRight />
                        )}
                      </i>
                    </div>
                    <Collapse isOpen={collapseOpen === key}>
                      <Nav style={{ backgroundColor: "white" }}>
                        {prop.options.map((innerProp, innerKey) => {
                          if (
                            !adminContext.company.id &&
                            !innerProp.showWithoutCompany
                          ) {
                            return null;
                          }
                          if (!innerProp.hide) {
                            return (
                              <NavItem key={innerKey} className="w-100">
                                <AccordionLinkItem
                                  to={prop.layout + innerProp.path}
                                  icon={innerProp.icon}
                                  name={innerProp.name}
                                  onClick={() => setCollapseOpen(null)}
                                  className="days-one text-gray accordion-menu-toggle"
                                />
                              </NavItem>
                            );
                          }
                          return null;
                        })}
                      </Nav>
                    </Collapse>
                  </div>
                </NavItem>
              );
            } else {
              return (
                <NavItem key={key}>
                  <AccordionLinkItem
                    to={prop.layout + prop.path}
                    icon={prop.icon}
                    name={prop.name}
                    onClick={() => setCollapseOpen(null)}
                    className="days-one text-white"
                  />
                </NavItem>
              );
            }
            // If the user is not on a native device, then render the regular menu
          } else {
            if (prop.options.length > 0 && !prop.path.includes("/reports")) {
              return (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle className="text-white days-one" nav caret>
                    <i className={prop.icon + " hover-animate"} />
                    {prop.name}
                  </DropdownToggle>
                  <DropdownMenu>
                    {React.Children.toArray(
                      prop.options.map((innerProp, key) => {
                        if (
                          !adminContext.company.id &&
                          !innerProp.showWithoutCompany
                        ) {
                          return null;
                        }
                        if (!innerProp.hide) {
                          return (
                            <DropdownItem>
                              <NavLink
                                to={prop.layout + innerProp.path}
                                id={innerProp.path}
                                tag={NavLinkRRD}
                                onClick={closeCollapse}
                                className="days-one text-gray"
                              >
                                {innerProp.short_description ? (
                                  <Tooltip
                                    title={innerProp.short_description}
                                    innerContent={
                                      <div>
                                        <i
                                          className={
                                            innerProp.icon + " hover-animate"
                                          }
                                        />
                                        {"  "}
                                        {innerProp.name}
                                      </div>
                                    }
                                  />
                                ) : (
                                  <div>
                                    <i
                                      className={
                                        innerProp.icon + " hover-animate"
                                      }
                                    />
                                    {"  "}
                                    {innerProp.name}
                                  </div>
                                )}
                              </NavLink>
                            </DropdownItem>
                          );
                        }
                      })
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              );
            } else {
              return (
                <NavItem key={key}>
                  <NavLink
                    to={prop.layout + prop.path}
                    tag={NavLinkRRD}
                    onClick={closeCollapse}
                    className="days-one text-white"
                  >
                    <i className={prop.icon + " hover-animate"} />
                    {prop.name}
                  </NavLink>
                </NavItem>
              );
            }
          }
        }
        return null;
      })
    );
  };

  const { bgColor, routes, logo, logoOriginal } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setShowRecentPages(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-dark bg-default pl-1"
      expand="lg"
      id="sidenav-main"
      style={
        adminContext.isNative && !isSmallScreen
          ? { overflowY: "auto", overflowX: "hidden" }
          : undefined
      }
    >
      <Container className="m-2 mt-4 w-100" fluid>
        {/* Toggler */}
        <button
          className="text-white null-border-radius navbar-toggler"
          id="navbar-collapse-main"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        {logo ? (
          <NavbarBrand className="pt-0 mr-4" {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logoOriginal.imgSrc}
            />
          </NavbarBrand>
        ) : null}

        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Navigation */}
          <Nav navbar>{createLinks(routes)}</Nav>

          {/* Divider */}
          <hr className="my-3" />
          {/* Heading */}
          <h6 className="navbar-heading text-muted">Support</h6>
          {/* Navigation */}
          <Nav className="mb-md-3" navbar>
            <NavItem>
              <NavLink
                className="text-white days-one"
                href="https://www.lightningpayroll.com.au/faq"
              >
                <i className="fa-regular fa-home fa-xl text-yellow hover-animate" />
                Homepage
              </NavLink>
            </NavItem>
            {adminContext.isNative && (
              <NavItem>
                <NavLink
                  className="text-white days-one"
                  href="#"
                  onClick={adminContext.sendLogsToSupport}
                >
                  <i className="fa-regular fa-home fa-xl text-yellow hover-animate" />
                  Send Console Logs
                </NavLink>
              </NavItem>
            )}
            <NavItem>
              <NavLink className="text-white days-one" href="/auth/login">
                <i className="fa-regular fa-key fa-xl text-yellow hover-animate" />
                Login
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="text-white days-one" href="/admin/logout">
                <i className="fa-regular fa-person-from-portal fa-xl text-yellow hover-animate" />
                Logout
              </NavLink>
            </NavItem>
            {adminContext.company.id && (
              <NavItem>
                <NavLink
                  className="text-white days-one"
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    setShowRecentPages(true);
                  }}
                >
                  <i className="fa-regular fa-clock-rotate-left text-yellow hover-animate" />
                  Recently Visited
                </NavLink>
              </NavItem>
            )}
          </Nav>
          {adminContext.isAdmin && (
            <>
              {/* Divider */}
              <hr className="my-3" />
              {/* Heading */}
              <h6 className="navbar-heading text-muted">Admin Only</h6>
              <Nav className="mb-md-3" navbar>
                <NavItem>
                  <NavLink
                    className="text-white days-one"
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      adminContext.history.push("/admin/login_as_customer");
                    }}
                  >
                    <i className="fa-regular fa-user text-yellow hover-animate" />
                    Login As Customer
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="text-white days-one"
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      adminContext.history.push("/admin/trials-dashboard");
                    }}
                  >
                    <i className="fa-regular fa-file-lines text-yellow hover-animate" />
                    Trials Dashboard
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="text-white days-one"
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      adminContext.history.push("/admin/server_logs");
                    }}
                  >
                    <i className="fa-regular fa-file-lines text-yellow hover-animate" />
                    Server Logs
                  </NavLink>
                </NavItem>
              </Nav>
            </>
          )}
        </Collapse>
        <Modal isOpen={showRecentPages}>
          <Container className="my-3">
            <Row>
              <Col>
                <h1 className="days-one text-center my-2">
                  Recent History{" "}
                  <i className="mx-3 fa-regular fa-history fa-lg text-yellow" />{" "}
                </h1>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col md="2"></Col>
              <Col md="8">
                <ul>
                  {getRecentPages().length > 0
                    ? getRecentPages().map((innerProp, key) => {
                        if (getNameFromPath(innerProp) === "") {
                          return null;
                        }
                        return (
                          <li key={innerProp} className="mx-3 my-2">
                            <Link
                              to={innerProp}
                              onClick={closeCollapse}
                              className="days-one text-gray"
                            >
                              <span
                                onClick={() => {
                                  setShowRecentPages(false);
                                }}
                              >
                                {getNameFromPath(innerProp)}
                              </span>
                            </Link>
                          </li>
                        );
                      })
                    : null}
                </ul>
              </Col>
              <Col md="2"></Col>
            </Row>
            <Row>
              <Col md="2"></Col>
              <Col md="8">
                <Button
                  color="secondary"
                  onClick={() => setShowRecentPages(false)}
                  className="float-center my-3 width-100"
                >
                  <FaWindowClose className="mr-3 mb-1" />
                  Close
                </Button>
              </Col>
              <Col md="2"></Col>
            </Row>
          </Container>
        </Modal>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
