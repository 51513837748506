import { useContext, useState, useEffect } from "react";
import AdminContext from "../../../AdminContext";
import Wizard from "../../../components/Widgets/Wizard";
import SuperDepositSelectView from "./SuperDepositSelectView";
import SuperStreamDetailsView from "./SuperStreamDetailsView";
import RemoteValidationView from "./RemoteValidationView";
import SuperStreamSummaryView from "./SuperStreamSummaryView";
import ValidationPage from "../../../components/Widgets/WizardViews/ValidationPage";

const SuperStreamContributionWizard = ({ multiple = false }) => {
  const adminContext = useContext(AdminContext);
  const [step, setStep] = useState(0);
  const [nextEnabled, setNextEnabled] = useState(true);
  const [forceUpdateButtons, setForceUpdateButtons] = useState(false);
  const [buttonsVisible, setButtonsVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [validationItems, setValidationItems] = useState([]);

  const [wizardInfo, setWizardInfo] = useState({});

  const [rows, setRows] = useState({
    deposits: [],
    superstream_messages: [],
  });

  const [selectedRows, setSelectedRows] = useState({
    deposits: [],
    superstream_messages: [],
  });

  useEffect(() => {
    setButtonsVisible(!loading);
  }, [loading]);

  // Update the wizardInfo when the rows or selectedRows change.
  useEffect(() => {
    setWizardInfo({
      ...wizardInfo,
      selected_deposits: selectedRows.deposits,
      superstream_messages: selectedRows.superstream_messages,
    });
  }, [selectedRows]);

  const handleNext = () => {
    if (step < wizardSteps.length - 1) {
      setStep(step + 1);
    } else {
      adminContext.history.goBack();
    }
  };

  const validate = () => {
    setLoading(true);

    adminContext.postRequest(
      adminContext.constants.BASE_URL + "/super-fund-deposits/superstream/superstream-contribution",
      wizardInfo,
      (response) => {
        setLoading(false);

        if (response.data.superstream_messages) {
          setWizardInfo({
            ...wizardInfo,
            superstream_messages: response.data.superstream_messages,
          });
        }
        if (response.data.validation_items) {
          if (response.data.validation_items.length === 0) {
            handleNext();
            return;
          }
          setValidationItems(response.data.validation_items);
        }
      }
    );
  };

  const wizardSteps = [
    <SuperDepositSelectView
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      loading={loading}
      setLoading={setLoading}
      rows={rows}
      setRows={setRows}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      setStep={setStep}
    />,
    <SuperStreamDetailsView
      setNextEnabled={setNextEnabled}
      setButtonsVisible={setButtonsVisible}
      buttonsVisible={buttonsVisible}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      loading={loading}
      setLoading={setLoading}
      setForceUpdateButtons={setForceUpdateButtons}
    />,
    <ValidationPage
      setNextEnabled={setNextEnabled}
      setButtonsVisible={setButtonsVisible}
      handleNext={handleNext}
      loading={loading}
      setLoading={setLoading}
      validate={validate}
      validationItems={validationItems}
    />,
    <RemoteValidationView
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      setButtonsVisible={setButtonsVisible}
      handleNext={handleNext}
      loading={loading}
      setLoading={setLoading}
      validate={validate}
    />,
    <SuperStreamSummaryView
      setNextEnabled={setNextEnabled}
      wizardInfo={wizardInfo}
      setButtonsVisible={setButtonsVisible}
      handleNext={handleNext}
      loading={loading}
      setLoading={setLoading}
    />,
  ];

  return (
    <Wizard
      wizardSteps={wizardSteps}
      handleNext={handleNext}
      hideBack={true}
      buttonsVisible={buttonsVisible}
      cancelCallback={() => {
        adminContext.setNewSuperFundDeposits([]);
        adminContext.history.goBack();
      }}
      nextEnabled={nextEnabled}
      step={step}
      setStep={setStep}
      forceUpdateButtons={forceUpdateButtons}
      setForceUpdateButtons={setForceUpdateButtons}
      isBackDisabled={() => {
        return (
          step === 0 ||
          (adminContext.newSuperFundDeposits.length > 0 && step === 1)
        );
      }}
      getLastStep={() => {
        return wizardSteps.length - 1;
      }}
    />
  );
};
export default SuperStreamContributionWizard;
