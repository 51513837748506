import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Alert,
  Col,
  Spinner,
  Row,
} from "reactstrap";
import { useHistory, useParams } from "react-router";
import axios from "axios";
import { getBaseUrl } from "../../variables/Config";

const Unsubscribe = () => {
  const isMounted = useRef(true);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { unsubscribeHash } = useParams(); // Get the hash from the URL
  const history = useHistory();

  useEffect(() => {
    isMounted.current = true;

    // Perform the unsubscribe action if the hash is present
    if (unsubscribeHash) {
      unsubscribeUser(unsubscribeHash);
    } else {
      setErrorMessage("Invalid unsubscribe link.");
    }

    return () => {
      isMounted.current = false;
    };
  }, [unsubscribeHash]);

  const unsubscribeUser = (hash) => {
    setLoading(true);
    axios
      .get(`${getBaseUrl()}/unsubscribe/${hash}`)
      .then((response) => {
        if (isMounted.current) {
          if (response.data.message) {
            setSuccessMessage(response.data.message);
          } else {
            setErrorMessage("Unsubscribe failed.");
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error during unsubscribe:", error);
        if (isMounted.current) {
          setErrorMessage("An error occurred. Please try again later.");
          setLoading(false);
        }
      });
  };

  return (
    <>
      <Col lg="8" md="8" className="mx-auto">
        {errorMessage && (
          <Row>
            <Col>
              <Alert color="danger">
                <strong>{errorMessage}</strong>
              </Alert>
            </Col>
          </Row>
        )}
        {successMessage && (
          <Row>
            <Col>
              <Alert color="success">
                <strong>{successMessage}</strong>
              </Alert>
            </Col>
          </Row>
        )}

        <Row>
          <Col lg="12" md="12">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="text-center">
                {loading ? (
                  <Spinner animation="border" role="status" />
                ) : (
                  <>
                    {successMessage && (
                      <Button
                        onClick={() => history.push("/")}
                        color="primary"
                        className="btn-lg text-lg width-100 m-2"
                      >
                        Go to Homepage
                      </Button>
                    )}
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Unsubscribe;
