import AdminContext from "../../AdminContext";
import { useState, useContext } from "react";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import DateLabel from "../../components/Widgets/DateLabel";
import { Card, CardBody, Button } from "reactstrap";

const SubscriptionExpired = () => {
  const adminContext = useContext(AdminContext);
  const [loading, setLoading] = useState(false);

  const handleRenewSubscription = () => {
    setLoading(true);
    // Simulate an action or API call
    setTimeout(() => {
      setLoading(false);
      adminContext.loginToWebAccount(true);
    }, 1000); // Simulates a delay before navigating
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Card className="bg-secondary mx-3 my-3 shadow border-0">
          <CardBody className="text-center">
            <h1 className="days-one">Subscription Expired</h1>
            <h4 className="text-dark mb-3">
              Your {adminContext.isTrial ? "trial " : ""}subscription has
              expired
            </h4>
            <p className="text-muted mb-4">
              Expiry Date: <DateLabel value={adminContext.primarySubscriptionExpiry} />
            </p>
            <p className="text-muted mb-4">
              Please click the button below to pay for your annual subscription
              and continue using Lightning Payroll.
            </p>
            <Button
              color="primary"
              onClick={handleRenewSubscription}
              className="px-4"
            >
              {adminContext.isTrial ? "Purchase" : "Renew"} Subscription
            </Button>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default SubscriptionExpired;
